import { extractErrorMessage, getLocalizedError } from '@takamol/svp-shared-components/errorHandling';

import PasswordApi from '@/api/password.api';
import countriesCodes from '@/app/auth/utils/countryCodes/country-codes.json';
import store from '@/store';

export const validateToken = async (to, from, next) => {
  if (!to.query.password_token) await next({ name: 'login' });
  try {
    let countryCodeInfo;

    const { data: tokenInfo } = await PasswordApi.validateResetPasswordToken({ password_token: to.query.password_token });

    if (tokenInfo?.country_code) {
      countryCodeInfo = countriesCodes.find((countryCode) => countryCode.dialCode === tokenInfo?.country_code) || null;
    }
    store.commit('SET_TOKEN_INFO', { ...tokenInfo, countryCodeInfo });
    next();
  } catch ({ response }) {
    await next({
      name: 'login',
      query: { errorMessage: getLocalizedError(extractErrorMessage(response.data), store.getters.lang) }
    });
  }
};
