const module = {
  state: {
    tokenInfo: {
      email: '',
      phone_number: '',
      country_code: '',
      contryCodeInfo: {}
    }
  },
  getters: {
    tokenInfo: state => state.tokenInfo
  },
  mutations: {
    'SET_TOKEN_INFO' (state, tokenInfo) {
      state.tokenInfo = { ...tokenInfo };
    }
  }
};

export default module;
