export const FeatureFlagDictionary = {
  ADMIN_SHOW_TC_LOCAL_TIME: 'admin_show_tc_local_time',
  ASSESSOR_PROMETRIC_ENABLED: 'assessor_prometric_enabled',
  ASSESSOR_REGISTRATION_REQUEST: 'assessor_registration_request',
  HIDE_SESSIONS_REPORT_PAGE_FOR_TC: 'hide_sessions_report_page_for_tc',
  ENABLE_EXAM_CONSTRAINT_SESSION_MIN_HOURS_BEFORE_START: 'enable_exam_constraint_session_min_hours_before_start',
  LABOR_CANCEL_RESERVATION: 'labor_cancel_reservation',
  LABOR_CREATE_RESERVATION: 'labor_create_reservation',
  LABOR_RESERVATION_CREDITS: 'labor_reservation_credits',
  LABOR_SHOW_TC_LOCAL_TIME: 'labor_show_tc_local_time',
  LABOR_VIEW_CANCELLATION_DETAILS: 'labor_view_cancellation_details',
  LEGISLATOR_APPROVE_ASSESSOR_APPLICATION: 'legislator_approve_assessor_application',
  LEGISLATOR_MANAGE_ASSESSORS_CERTIFICATES: 'legislator_manage_assessors_certificates',
  LEGISLATOR_REJECT_ASSESSOR_APPLICATION: 'legislator_reject_assessor_application',
  LEGISLATOR_VIEW_ASSESSORS_DETAILS: 'legislator_view_assessors_details',
  LEGISLATOR_VIEW_ASSESSORS_LIST: 'legislator_view_assessors_list',
  LEGISLATOR_VIEW_REGISTERED_LABORS_RESULTS: 'legislator_view_registered_labors_results',
  LEGISLATOR_VIEW_SESSIONS_AND_RESERVATIONS: 'legislator_view_sessions_and_reservations',
  TC_CANCEL_RESERVATION: 'tc_cancel_reservation',
  TC_CANCEL_SESSION: 'tc_cancel_session',
  TC_CREATE_SESSION: 'tc_create_session',
  TC_INVITE_ASSESSOR: 'tc_invite_assessor',
  TC_PROMETRIC_ENABLED: 'tc_prometric_enabled',
  TC_START_EXAM: 'tc_start_exam',
  TC_UPDATE_PRACTICAL_RESULTS: 'tc_update_practical_results',
  TC_VIEW_ASSESSORS_DETAILS: 'tc_view_assessors_details',
  TC_VIEW_ASSESSORS_LIST: 'tc_view_assessors_list',
  TC_VIEW_CANCELLATION_DETAILS: 'tc_view_cancellation_details',
  TC_VIEW_SESSIONS_ASSIGNED_ASSESSOR: 'tc_view_sessions_assigned_assessor',
  USE_NEW_BOOKING: 'use_new_booking',
  USE_NEW_CHANGE_PASSWORD: 'use_new_change_password',
  USE_NEW_FORGOT_PASSWORD: 'use_new_forgot_password',
  USE_NEW_LABOR_SIGN_UP: 'use_new_labor_sign_up',
  USE_NEW_LOGIN: 'use_new_login',
  USE_NEW_SET_PASSWORD: 'use_new_set_password',
  USE_NEW_REGISTER: 'use_new_register',
  ENABLE_LABOR_RESHEDULING_FLOW: 'enable_labor_rescheduling_flow',
  SKIP_RECAPTCHA_STEP: 'skip_recaptcha_step',
  ENABLE_OTP_IN_RESPONSE: 'enable_otp_in_response',
  LABOR_PASSPORT_RECOGNITION_DISABLED: 'disable_labor_passport_recognition',
  ASSESSOR_PASSPORT_RECOGNITION_DISABLED: 'disable_assessor_passport_recognition',
  SKIP_PHONE_OTP: 'skip_sms_otp'
};
